import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import TableComponent from '../../shared/table';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';

const QuotesList = () => {

    const [currentPage, setcurrentPage] = useState(1);
    const [isNext, setNext] = useState(false);
    const [isPrev, setPrev] = useState(false);

    const columns = [{
        field: 'category_name',
        lable: 'Name'
    }, {
        field: 'image',
        lable: 'Image'
    }];

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    const nextPage = () => {
        let page = currentPage + 1;
        setcurrentPage(page);
        getCategory(page);
    }

    const prevPage = () => {
        let page = currentPage - 1;
        setcurrentPage(page);
        getCategory(page);
    }

    useEffect(() => {
        getCategory(1);
    }, []);

    useEffect(() => {
        isRecall && getCategory(currentPage);
    }, [isRecall]);

    const getCategory = (page) => {
        setLoading(true);
        axios.post(API_URL.ADMIN_QUOTES_LIST, { page: page }).then(res => {
            setData(res.data.data.data);
            if (res.data.data.next_page_url) {
                setNext(true)
            } else {
                setNext(false)
            }
            if (res.data.data.prev_page_url) {
                setPrev(true)
            } else {
                setPrev(false)
            }
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_QUOTES_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    };

    const onEdit = (item) => {
        navigate(PAGE_ROUTE.ADMIN_QUOTES_EDIT + item.id, { replace: true });
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <h4 className='card-title'>Quotes
                
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                            <li>
                                <NavLink to={PAGE_ROUTE.ADMIN_QUOTES_CREATE} className="btn btn-outline-success btn-sm me-2">
                                    Add
                                </NavLink>
                            </li>
                            <li className="page-item disabled">
                                {isPrev ? <button className="btn btn-outline-primary btn-sm me-2" onClick={prevPage}>Previous</button> : ""}
                            </li>
                            <li className="page-item">
                                {isNext ? <button className="btn btn-outline-primary btn-sm" onClick={nextPage}>Next</button> : ""}
                            </li>
                        </ul>
                    </nav>
                </h4>

                {
                    isLoading && <LoaderComponent height={100} width={100}></LoaderComponent>
                }
                {
                    !isLoading && <div className="table-responsive">
                        <div className='table-responsive'>
                            <TableComponent
                                columns={columns}
                                data={data}
                                onDelete={onDelete}
                                onEdit={onEdit}>
                            </TableComponent>
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end">
                                <li className="page-item disabled">
                                    {isPrev ? <button className="btn btn-outline-primary btn-sm me-2" onClick={prevPage}>Previous</button> : ""}
                                </li>
                                <li className="page-item">
                                    {isNext ? <button className="btn btn-outline-primary btn-sm" onClick={nextPage}>Next</button> : ""}
                                </li>
                            </ul>
                        </nav>
                    </div>
                }
            </div>
        </div>
    )
}

export default QuotesList