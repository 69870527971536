import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import TableComponent from '../../shared/table';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import moment from 'moment/moment';

const Ads = () => {

  const columns = [{
    field: 'name',
    lable: 'Name'
  }, {
    field: 'contact_no',
    lable: 'Contact No'
  }, {
    field: 'views',
    lable: 'Views'
  }, {
    field: 'updated_at',
    lable: 'Last Update'
  }];

  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isRecall, setIsRecall] = useState(false);

  useEffect(() => {
    getBussiness();
  }, []);

  useEffect(() => {
    isRecall && getBussiness();
  }, [isRecall]);

  const getBussiness = () => {
    setLoading(true);
    axios.post(API_URL.ADMIN_ADS_LIST, {}).then(res => {
      res.data.data.map((element)=>{element.updated_at=moment(element.updated_at).format('lll')})
      setData(res.data.data);
      setIsRecall(false);
      setLoading(false);
    }, error => {
      setLoading(false);
    })
  };


  return (
    <div>
      <div className='card'>
        <div className='card-body'>
          <h4 className='card-title'>ADS
            <NavLink to={PAGE_ROUTE.ADMIN_BUSSINESS_CREATE} className="btn btn-primary">
              Add
            </NavLink>
          </h4>

          {
            isLoading && <LoaderComponent height={100} width={100}></LoaderComponent>
          }
          {
            !isLoading && <div className="table-responsive">
              <div className='table-responsive'>
                <TableComponent
                  columns={columns}
                  data={data}
                >
                </TableComponent>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default Ads