import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { BiCategory } from 'react-icons/bi';
import { GrProductHunt } from 'react-icons/gr';
import { AiFillDashboard } from 'react-icons/ai';
import { GoFileSubmodule } from 'react-icons/go';
import { FiUsers } from 'react-icons/fi';
import { FcBusinessman } from 'react-icons/fc';
import { FaSlidersH } from 'react-icons/fa';
import { BsFillChatLeftQuoteFill } from 'react-icons/bs'
import { ImQuotesLeft } from 'react-icons/im'
import { FcAdvertising } from 'react-icons/fc'

export default class Sidebar extends Component {
    render() {
        return (
            <>
                <div className="web-category">
                    <nav className="sidebar sidebar-offcanvas" id="sidebar">
                        <ul className="nav">
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/dashboard">
                                    <AiFillDashboard size={20}></AiFillDashboard>
                                    <span className="menu-title">Dashboard</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/category">
                                    <BiCategory size={20}></BiCategory>
                                    <span className="menu-title">Category</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/sub-category">
                                    <GoFileSubmodule size={20}></GoFileSubmodule>
                                    <span className="menu-title">Sub Category</span>
                                </NavLink>
                            </li>
                            <li className="nav-item ">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/product">
                                    <GrProductHunt size={20}></GrProductHunt>
                                    <span className="menu-title">Posters</span>
                                </NavLink>
                            </li>
                            <li className="nav-item ">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/users">
                                    <FiUsers size={20}></FiUsers>
                                    <span className="menu-title">Users</span>
                                </NavLink>
                            </li>
                            <li className="nav-item ">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/bussiness">
                                    <FcBusinessman size={20}></FcBusinessman>
                                    <span className="menu-title">Bussiness</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/slider">
                                    <FaSlidersH size={20}></FaSlidersH>
                                    <span className="menu-title">Slider</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/quotes">
                                    <BsFillChatLeftQuoteFill size={20}></BsFillChatLeftQuoteFill>
                                    <span className="menu-title">Quotes</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/quotescategory">
                                    <ImQuotesLeft size={20}></ImQuotesLeft>
                                    <span className="menu-title">Quotes Category</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/ads">
                                    <FcAdvertising size={20}></FcAdvertising>
                                    <span className="menu-title">ADS</span>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div className="mobile-category">
                    <nav className="sidebar sidebar-offcanvas" id="sidebar">
                        <ul className="nav">
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/dashboard">
                                    <AiFillDashboard size={20}></AiFillDashboard>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/category">
                                    <BiCategory size={20}></BiCategory>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/sub-category">
                                    <GoFileSubmodule size={20}></GoFileSubmodule>
                                </NavLink>
                            </li>
                            <li className="nav-item ">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/product">
                                    <GrProductHunt size={20}></GrProductHunt>
                                </NavLink>
                            </li>
                            <li className="nav-item ">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/users">
                                    <FiUsers size={20}></FiUsers>
                                </NavLink>
                            </li>
                            <li className="nav-item ">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/bussiness">
                                    <FcBusinessman size={20}></FcBusinessman>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/slider">
                                    <FaSlidersH size={20}></FaSlidersH>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/quotes">
                                    <BsFillChatLeftQuoteFill size={20}></BsFillChatLeftQuoteFill>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/quotescategory">
                                    <ImQuotesLeft size={20}></ImQuotesLeft>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/ads">
                                    <FcAdvertising size={20}></FcAdvertising>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </>
        )
    }
}