import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import Crop from '../../shared/crop';

const ProductFormComponent = () => {

   
    const [imagePreview, setImagePreview] = useState('');
    let { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [categoryDrp, setCategoryDrpData] = useState([]);
    const [subCategoryDrp, setSubCategoryDrp] = useState([]);
    const [name, setName] = useState('');
    const [error, setError] = useState([]);
    const [formSpecData, setFormSpecData] = useState([]);
    const [formData, setFormData] = useState({
        category_id: '',
        sub_category_id: '',
        image: '',
        image_url: '',
        back_color: '',
        text_color: '',
        tag_color: '',
        logo_align: 'Left',
        image_align: 'CENTER',
        description: '',
    });

    useEffect(() => {
        if (id) {
            onEdit();
        } else {
            getCategoryDrpData(() => {

            });
        }
    }, [id])

    const getCategoryDrpData = () => {
        axios.post(API_URL.ADMIN_CATEGORY_LIST, {}).then(res => {
            setCategoryDrpData(res.data.data);
        })
    };

    const findImage = (category_id) => {
        let cRow = categoryDrp.find(item => item.id == category_id)
        setName(cRow.name)
    }

    useEffect(() => {
        if (categoryDrp.length > 0 && formData.category_id !== ''){
            findImage(formData.category_id)
        }
    }, [categoryDrp, formData.category_id])

    const getSubCategoryDrpData = (value) => {
        axios.post(API_URL.ADMIN_SUB_CATEGORY_BY_CATEGORY, { category_id: value }).then(res => {
            setSubCategoryDrp(res.data.data)
        })
    };




    const onSubmit = (e) => {
        e.preventDefault();
        if (isInvalid(formData.category_id)) {
            toast.error("Category Is Required");
            return false;
        }
        if (isInvalid(formData.sub_category_id)) {
            toast.error("Category Is Required");
            return false;
        }
        if (isInvalid(formData.logo_align)) {
            toast.error("Logo Align Is Required");
            return false;
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_POSTERS_SAVE;
        let body = formData;
        if (formSpecData && formSpecData.length) {
            body['specs'] = formSpecData;
        }
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_POSTERS_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Poster Updated Successfully', { autoClose: 3000 });
                }

                else {
                    toast.success('Poster Created Successfully', { autoClose: 3000 });
                }
                // setTimeout(() => {
                //     navigate(PAGE_ROUTE.ADMIN_PRODUCT_LIST, { replace: true });
                // }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            toast.error('Something went wrong..', { autoClose: 3000 })
            setLoading(false);
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value === null);
    };

    const onEdit = (event) => {
        axios.post(API_URL.ADMIN_POSTERS_GET + id).then((response) => {
            if (response.data.status === 1) {
                getCategoryDrpData();
                getSubCategoryDrpData(response.data.data.category_id);
                setFormData({
                    category_id: response.data.data.category_id,
                    sub_category_id: response.data.data.sub_category_id,
                    image_url: response.data.data.image,
                    back_color: response.data.data.back_color,
                    text_color: response.data.data.text_color,
                    tag_color: response.data.data.tag_color,
                    logo_align: response.data.data.logo_align,
                    image_align: response.data.data.image_align,
                    description: response.data.data.description
                })
            }
        });
    }

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>{id ? 'Edit' : 'Add'} Poster</h4>
                    {error.length > 0 &&
                        <>
                            {[
                                'danger',
                            ].map((variant) => (
                                <Alert key={variant} variant={variant}>
                                    {error.map((element, key) => {
                                        return (
                                            <div key={key}>{element}</div>
                                        )
                                    })}
                                </Alert>
                            ))}
                        </>
                    }
                    <form className="forms-sample" onSubmit={onSubmit}>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Category</label>
                                    <Form.Select aria-label="Category" value={formData.category_id}
                                        onChange={(event) => {
                                            getSubCategoryDrpData(event.target.value);
                                            setFormData({ ...formData, category_id: event.target.value })
                                        }}>
                                        value={formData.category_id}
                                        <option value="">Select</option>
                                        {
                                            categoryDrp.map((res, key) => {
                                                return (
                                                    <option key={key} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label htmlFor="Sub Category">Sub Category</label>
                                    <Form.Select
                                        aria-label="Sub Category"
                                        value={formData.sub_category_id}
                                        disabled={!formData.category_id}
                                        onChange={(event) => {
                                            setFormData({ ...formData, sub_category_id: event.target.value })
                                        }}>
                                        value={formData.sub_category_id}
                                        <option value="">Select</option>
                                        {
                                            subCategoryDrp.map((res, key) => {
                                                return (
                                                    <option key={key} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Background Color</label>
                                    <input
                                        type="text"
                                        name='back_color'
                                        value={formData.back_color}
                                        className="form-control"
                                        id="back_color"
                                        onChange={(event) => {
                                            setFormData({ ...formData, back_color: event.target.value })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Text Color</label>
                                    <input
                                        type="text"
                                        name='text_color'
                                        value={formData.text_color}
                                        className="form-control"
                                        id="text_color"
                                        onChange={(event) => {
                                            setFormData({ ...formData, text_color: event.target.value })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Logo Align</label>
                                    <Form.Select aria-label="logo_align"
                                        value={formData.logo_align}
                                        id="logo_align"
                                        onChange={(event) => {
                                            setFormData({ ...formData, logo_align: event.target.value })
                                        }}>
                                        <option value="">Select</option>
                                        <option value="Left">Left</option>
                                        <option value="Right">Right</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Image Align</label>
                                    <Form.Select aria-label="image_align"
                                        value={formData.image_align}
                                        id="image_align"
                                        onChange={(event) => {
                                            setFormData({ ...formData, image_align: event.target.value })
                                        }}>
                                        <option value="">Select</option>
                                        <option value="LEFT">Left</option>
                                        <option value="RIGHT">Right</option>
                                        <option value="CENTER">Center</option>
                                    </Form.Select>
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="Description">Description</label>
                                <Form.Control
                                    as="textarea"
                                    value={formData.description}
                                    style={{ height: '100px' }}
                                    onChange={(event) => {
                                        setFormData({ ...formData, description: event.target.value })
                                    }}
                                />
                            </div> */}
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label>Image</label>
                                    <Crop imagePreview={imagePreview} cropShape='round' aspectRatio={name === 'RIP' ? 2 / 3 : 1 / 1} name={name} guid={''}
                                        onChangeCropper={async ({ fileCropped, fileCroppedBase64 }) => {
                                            setFormData({ ...formData, image: fileCroppedBase64 })
                                            setImagePreview(fileCroppedBase64);
                                            //   await uploadFile(fileCropped)
                                        }} />
                                </div>
                            </div>
                            {id &&
                                <div className='col-md-12'>
                                    <label>Preview</label>
                                    <div>
                                        <a href={formData.image_url} target="_blank" rel="noopener noreferrer">
                                            <img src={formData.image_url} height={100} width={100} alt="poster_image" />
                                        </a>
                                    </div>
                                </div>
                            }
                            <div className='text-end'>
                                {
                                    isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                                }
                                <button type="submit" className="btn btn-primary">{id ? 'Update' : 'Create'}</button>
                                <NavLink to="/admin/product" className="btn btn-secondary">
                                    Cancel
                                </NavLink>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

}
export default ProductFormComponent;