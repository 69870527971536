import React from 'react';
import swal from 'sweetalert';
import { MdPictureAsPdf } from 'react-icons/md';

const TableComponent = ({ columns, data, onDelete, onEdit, isShowAction = true, isEditBtn = true, isDeleteBtn = true }) => {
    const onDeleteClick = (item) => {
        swal({
            title: "Are you sure want to delete?",
            icon: "warning",
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            buttons: true
        }).then((willDelete) => {
            if (willDelete) {
                onDelete && onDelete(item);
            }
        });
    }

    const onEditClick = (item) => {
        onEdit && onEdit(item);
    }

    return (
        <React.Fragment>
            {
                (data && data.length > 0) &&
                <table className='table table-hover'>
                    <thead className='thead'>
                        <tr>
                            {
                                columns.map((element, key) => {
                                    return (
                                        <th key={key}>{element.lable}</th>
                                    )
                                })
                            }
                            {isShowAction &&
                                <th>Action</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((element, key) => {
                                return (
                                    <tr key={key}>
                                        {
                                            columns.map((childElement, childKey) => {
                                                return (
                                                    (childElement.field !== 'image') ?
                                                        ((childElement.isLink) ?
                                                            <td key={childKey}>
                                                                <a href={element[childElement.link]} target="_blank" rel="noopener noreferrer"><MdPictureAsPdf size={20}></MdPictureAsPdf>&nbsp;{element[childElement.field]}</a>
                                                            </td> :
                                                            <td key={childKey}>{element[childElement.field]}</td>)
                                                        :
                                                        <td key={childKey}><img src={element[childElement.field]} width={100} height={childElement.height ? childElement.height : 100} alt='slider_iamges' /></td>
                                                )
                                            })
                                        }
                                        {isShowAction &&
                                            <td>
                                                {isEditBtn &&
                                                    <button type="button" className="btn btn-outline-primary btn-sm me-2" onClick={() => onEditClick(element)}>Edit</button>
                                                }
                                                {isDeleteBtn &&
                                                    <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={() => onDeleteClick(element)}>Delete</button>
                                                }
                                            </td>
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
        </React.Fragment>
    )
}

export default TableComponent; 