// const ADMIN_BASE_URL = 'http://127.0.0.1:8000/api/admin/';
// const SITE_BASE_URL = 'http://127.0.0.1:8000/api/';
const SITE_BASE_URL = 'https://statush.com/api/';
const ADMIN_BASE_URL = 'https://statush.com/api/admin/';

export const PAGE_ROUTE = {
    ADMIN_DASHBOARD: '/admin/dashboard',

    ADMIN_CATEGORY_LIST: '/admin/category',
    ADMIN_CATEGORY_CREATE: '/admin/category/create',
    ADMIN_CATEGORY_EDIT: '/admin/category/edit/',

    ADMIN_SUB_CATEGORY_LIST: '/admin/sub-category',
    ADMIN_SUB_CATEGORY_CREATE: '/admin/sub-category/create',
    ADMIN_SUB_CATEGORY_EDIT: '/admin/sub-category/edit/',

    ADMIN_PRODUCT_LIST: '/admin/product',
    ADMIN_PRODUCT_CREATE: '/admin/product/create',
    ADMIN_PRODUCT_EDIT: '/admin/product/edit/',

    ADMIN_USERS_LIST: '/admin/users',
    ADMIN_USERS_CREATE: '/admin/users/create',
    ADMIN_USERS_EDIT: '/admin/users/edit/',

    ADMIN_BUSSINESS_LIST: '/admin/bussiness',
    ADMIN_BUSSINESS_CREATE: '/admin/bussiness/create',
    ADMIN_BUSSINESS_EDIT: '/admin/bussiness/edit/',

    ADMIN_SLIDER_LIST: '/admin/slider',
    ADMIN_SLIDER_CREATE: '/admin/slider/create',
    ADMIN_SLIDER_EDIT: '/admin/slider/edit/',

    ADMIN_QUOTES_LIST: '/admin/quotes',
    ADMIN_QUOTES_CREATE: '/admin/quotes/create',
    ADMIN_QUOTES_EDIT: '/admin/quotes/edit/',

    ADMIN_QUOTES_CATEGORY_LIST: '/admin/quotescategory',
    ADMIN_QUOTES_CATEGORY_CREATE: '/admin/quotescategory/create',
    ADMIN_QUOTES_CATEGORY_EDIT: '/admin/quotescategory/edit/',
}

export const API_URL = {

    SITE_CONTACT_ADD: `${SITE_BASE_URL}save-inquiry`,
    SITE_ALL_STATE: `${SITE_BASE_URL}all-states`,
    SITE_CONTACT_INFO: `${SITE_BASE_URL}contact-info`,
    SITE_PRODUCT: `${SITE_BASE_URL}products`,
    SITE_PRODUCT_BY_CATEGORY_SLUG: `${SITE_BASE_URL}products-by-category-slug`,
    SITE_SUBCATEGORY: `${SITE_BASE_URL}sub-category-by-category`,
    SITE_PRODUCT_BY_SUB_CATEGORY_SLUG: `${SITE_BASE_URL}products-by-sub-category-slug`,
    SITE_PRODUCT_BY_SIZE_SLUG: `${SITE_BASE_URL}products-by-size-slug`,
    SITE_PRODUCT_BY_SERIES_SLUG: `${SITE_BASE_URL}products-by-series-slug`,
    SITE_PRODUCT_BY_ID: `${SITE_BASE_URL}product-by-id`,
    SITE_PRODUCT_BY_SLUG: `${SITE_BASE_URL}product-by-slug`,
    SITE_CATEGORY: `${SITE_BASE_URL}all-category`,
    SITE_SHOW_CATEGORY: `${SITE_BASE_URL}show-category`,
    SITE_SHOW_SUB_CATEGORY: `${SITE_BASE_URL}show-sub-category`,
    SITE_PACKINGS: `${SITE_BASE_URL}packing-details`,
    SITE_NEWS: `${SITE_BASE_URL}news`,
    SITE_NEWS_BY_SLUG: `${SITE_BASE_URL}news-by-slug`,
    SITE_PDFS: `${SITE_BASE_URL}pdfs-list`,
    SITE_INFRASTRUCTURE_CATEGORY: `${SITE_BASE_URL}infrastructure-category`,
    SITE_SLIDERS: `${SITE_BASE_URL}sliders`,

    ADMIN_CATEGORY_LIST: `${ADMIN_BASE_URL}category`,
    ADMIN_CATEGORY_GET: `${ADMIN_BASE_URL}category/show?id=`,
    ADMIN_CATEGORY_SAVE: `${ADMIN_BASE_URL}category/store`,
    ADMIN_CATEGORY_UPDATE: `${ADMIN_BASE_URL}category/update`,
    ADMIN_CATEGORY_DELETE: `${ADMIN_BASE_URL}category/delete?id=`,

    ADMIN_SUB_CATEGORY_LIST: `${ADMIN_BASE_URL}sub-category`,
    ADMIN_SUB_CATEGORY_GET: `${ADMIN_BASE_URL}sub-category/show?id=`,
    ADMIN_SUB_CATEGORY_SAVE: `${ADMIN_BASE_URL}sub-category/store`,
    ADMIN_SUB_CATEGORY_UPDATE: `${ADMIN_BASE_URL}sub-category/update`,
    ADMIN_SUB_CATEGORY_DELETE: `${ADMIN_BASE_URL}sub-category/delete?id=`,
    ADMIN_SUB_CATEGORY_BY_CATEGORY: `${ADMIN_BASE_URL}category/sub-category`,


    ADMIN_SETTING_INQUIRIES: `${ADMIN_BASE_URL}setting/inquiries`,
    ADMIN_SETTING_SAVE: `${ADMIN_BASE_URL}setting/store`,
    ADMIN_SETTING_GET: `${ADMIN_BASE_URL}setting/show`,
    ADMIN_SETTING_PASSWORD: `${ADMIN_BASE_URL}setting/password`,
    ADMIN_ABOUT_US_SAVE: `${ADMIN_BASE_URL}setting/about`,
    LOGIN_URL: `${SITE_BASE_URL}login`,

    ADMIN_POSTERS_LIST: `${ADMIN_BASE_URL}posters`,
    ADMIN_POSTERS_GET: `${ADMIN_BASE_URL}posters/show?id=`,
    ADMIN_POSTERS_SAVE: `${ADMIN_BASE_URL}posters/store`,
    ADMIN_POSTERS_UPDATE: `${ADMIN_BASE_URL}posters/update`,
    ADMIN_POSTERS_DELETE: `${ADMIN_BASE_URL}posters/delete?id=`,

    ADMIN_USERS_LIST: `${ADMIN_BASE_URL}users`,
    ADMIN_ALL_USERS_LIST: `${ADMIN_BASE_URL}users/all`,
    ADMIN_USERS_GET: `${ADMIN_BASE_URL}users/show?id=`,
    ADMIN_USERS_SAVE: `${ADMIN_BASE_URL}users/store`,
    ADMIN_USERS_UPDATE: `${ADMIN_BASE_URL}users/update`,
    ADMIN_USERS_DELETE: `${ADMIN_BASE_URL}users/delete?id=`,

    ADMIN_BUSSINESS_LIST: `${ADMIN_BASE_URL}business`,
    ADMIN_BUSSINESS_GET: `${ADMIN_BASE_URL}business/show?id=`,
    ADMIN_BUSSINESS_SAVE: `${ADMIN_BASE_URL}business/store`,
    ADMIN_BUSSINESS_UPDATE: `${ADMIN_BASE_URL}business/update`,
    ADMIN_BUSSINESS_DELETE: `${ADMIN_BASE_URL}business/delete?id=`,

    ADMIN_ADS_LIST: `${ADMIN_BASE_URL}business/ads`,

    ADMIN_SLIDER_LIST: `${ADMIN_BASE_URL}slider`,
    ADMIN_SLIDER_GET: `${ADMIN_BASE_URL}slider/show?id=`,
    ADMIN_SLIDER_SAVE: `${ADMIN_BASE_URL}slider/store`,
    ADMIN_SLIDER_UPDATE: `${ADMIN_BASE_URL}slider/update`,
    ADMIN_SLIDER_DELETE: `${ADMIN_BASE_URL}slider/delete?id=`,

    ADMIN_QUOTES_LIST: `${ADMIN_BASE_URL}quotes`,
    ADMIN_QUOTES_GET: `${ADMIN_BASE_URL}quotes/show?id=`,
    ADMIN_QUOTES_SAVE: `${ADMIN_BASE_URL}quotes/store`,
    ADMIN_QUOTES_UPDATE: `${ADMIN_BASE_URL}quotes/update`,
    ADMIN_QUOTES_DELETE: `${ADMIN_BASE_URL}quotes/delete?id=`,

    ADMIN_QUOTES_CATEGORY_LIST: `${ADMIN_BASE_URL}quote-category`,
    ADMIN_QUOTES_CATEGORY_GET: `${ADMIN_BASE_URL}quote-category/show?id=`,
    ADMIN_QUOTES_CATEGORY_SAVE: `${ADMIN_BASE_URL}quote-category/store`,
    ADMIN_QUOTES_CATEGORY_UPDATE: `${ADMIN_BASE_URL}quote-category/update`,
    ADMIN_QUOTES_CATEGORY_DELETE: `${ADMIN_BASE_URL}quote-category/delete?id=`,

    ADMIN_LANGUAGES_LIST: `${SITE_BASE_URL}languages`,
    
}

