import React from 'react';
import Header from "../include/Header";
import Sidebar from "../include/Sidebar";
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './../admin/assets/css/admin.css';
import 'react-toastify/dist/ReactToastify.css';
    
// For GET requests
axios.interceptors.request.use(
    (req) => {
        // Add configurations here
        req.headers.Authorization = 'Bearer ' + sessionStorage.getItem('access_token');
        return req;
    },
    (err) => {
        if (err.response.status === 401) {
            sessionStorage.clear();
            window.location.href = '/login';
        }
        return Promise.reject(err);
    }
);

// For POST requests
axios.interceptors.response.use(
    (res) => {
        res.headers.Authorization = 'Bearer ' + sessionStorage.getItem('access_token');
        if (res.status === 401) {
            sessionStorage.clear();
            window.location.href = '/login';
        }
        return res;
    },
    (err) => {
        if (err.response.status === 401) {
            sessionStorage.clear();
            window.location.href = '/login';
        }
        return Promise.reject(err);
    }
);

const AdminLayoutRoute = ({ component: Component }) => (
    <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper ps-0 pe-0">
            <Sidebar />
            <div className="main-panel">
                <div className="content-wrapper">
                    {Component}
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
);

export default AdminLayoutRoute;  