import CategoryListComponent from '../admin/category/category-list';
import CategoryFormComponent from '../admin/category/category-form';
import DashboardComponent from '../admin/dashboard/dashboard';
import SubCategoryListComponent from '../admin/sub-category/sub-category-list';
import SubCategoryFromComponent from '../admin/sub-category/sub-category-form';
import ProductListComponent from '../admin/product/product-list';
import ProductFormComponent from '../admin/product/product-form';
import UserListComponent from '../admin/users/user-list';
import UserFormComponent from '../admin/users/user-form';
import BussinessListComponent from '../admin/bussiness/bussiness-list';
import BussinessFormComponent from '../admin/bussiness/bussiness-form';
import SliderListComponent from '../admin/slider/slider-list';
import SliderFormComponent from '../admin/slider/slider-form';
import QuotesFormComponent from '../admin/quotes/quotes-form';
import QuotesListComponent from '../admin/quotes/quotes-list';
import QuotesCategoryFormComponent from '../admin/quotes-category/quotes-category-form';
import QuotesCategoryListComponent from '../admin/quotes-category/quotes-category-list';
import Ads from '../admin/ads/ads';



const AdminRoutes = [
    { path: '', component: <DashboardComponent /> },
    { path: 'dashboard', component: <DashboardComponent /> },
    { path: 'category', component: <CategoryListComponent /> },
    { path: 'category/create', component: <CategoryFormComponent /> },
    { path: 'category/edit/:id', component: <CategoryFormComponent /> },
    { path: 'sub-category', component: <SubCategoryListComponent /> },
    { path: 'sub-category/create', component: <SubCategoryFromComponent /> },
    { path: 'sub-category/edit/:id', component: <SubCategoryFromComponent /> },
    { path: 'product', component: <ProductListComponent /> },
    { path: 'product/create', component: <ProductFormComponent /> },
    { path: 'product/edit/:id', component: <ProductFormComponent /> },
    { path: 'users', component: <UserListComponent /> },
    { path: 'users/create', component: <UserFormComponent /> },
    { path: 'users/edit/:id', component: <UserFormComponent /> },
    { path: 'bussiness', component: <BussinessListComponent /> },
    { path: 'bussiness/create', component: <BussinessFormComponent /> },
    { path: 'bussiness/edit/:id', component: <BussinessFormComponent /> },
    { path: 'slider', component: <SliderListComponent /> },
    { path: 'slider/create', component: <SliderFormComponent /> },
    { path: 'slider/edit/:id', component: <SliderFormComponent /> },
    { path: 'quotes', component: <QuotesListComponent /> },
    { path: 'quotes/create', component: <QuotesFormComponent /> },
    { path: 'quotes/edit/:id', component: <QuotesFormComponent /> },
    { path: 'quotescategory', component: <QuotesCategoryListComponent /> },
    { path: 'quotescategory/create', component: <QuotesCategoryFormComponent /> },
    { path: 'quotescategory/edit/:id', component: <QuotesCategoryFormComponent /> },
    { path: 'ads', component: <Ads /> },
];

export default AdminRoutes;