import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import TableComponent from '../../shared/table';
import LoaderComponent from '../../shared/loader';
import { AiOutlineSearch } from 'react-icons/ai';
import { Form } from 'react-bootstrap';
import moment from 'moment/moment';

import axios from 'axios';
const UserListComponent = () => {
    const [search, setSearch] = useState('');
    const [isActive, setActive] = useState('');
    const [currentPage, setcurrentPage] = useState(1);
    const [isNext, setNext] = useState(false);
    const [isPrev, setPrev] = useState(false);

    const columns = [
        {
            field: 'contact_no',
            lable: 'Contact No'
        }, {
            field: 'name',
            lable: 'Name'
        }, {
            field: 'active',
            lable: 'Active'
        },
        {
            field: 'created_at',
            lable: 'Created At'
        },
        {
            field: 'updated_at',
            lable: 'Last Update'
        }
    ];
    const [total,setTotal]=useState('');
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    const nextPage = () => {
        let page = currentPage + 1;
        setcurrentPage(page);
        getUsers(page, isActive);
    }

    const prevPage = () => {
        let page = currentPage - 1;
        setcurrentPage(page);
        getUsers(page, isActive);
    }
    useEffect(() => {
        getUsers(1, "");
    }, []);

    useEffect(() => {
        isRecall && getUsers(currentPage, isActive);
    }, [isRecall]);

    const getUsers = (page, active) => {
        setLoading(true);
        axios.post(API_URL.ADMIN_USERS_LIST, { page: page, search: (search.length > 2) ? search : "", active: active }).then(res => {
            setData(res.data.data);
            setTotal(res.data.total);
            res.data.data.map((element)=>{
                element.created_at=moment(element.created_at).format('lll')
                element.updated_at=moment(element.updated_at).format('lll')
            })
            if (res.data.next_page_url) {
                setNext(true)
            } else {
                setNext(false)
            }
            if (res.data.prev_page_url) {
                setPrev(true)
            } else {
                setPrev(false)
            }
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_USERS_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    };

    const onEdit = (item) => {
        navigate(PAGE_ROUTE.ADMIN_USERS_EDIT + item.id, { replace: true });
    };

    const updateStatus = (val) => {
        setActive(val);
        getUsers(1, val)
    };



    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>Users ({total}) 

                        <nav aria-label="Page navigation example">
                            <div className="input-group mt-3">
                                <div className="form-outline">
                                    <input type="search" value={search} placeholder="Search Here...." onChange={e => setSearch(e.target.value.trim())} className="form-control" />

                                </div>

                                <button type="button" style={{ borderRadius: '3px' }} className="btn btn-primary ms-2" onClick={() => getUsers(1, isActive)} >
                                    <AiOutlineSearch style={{ fontSize: '20px' }} />
                                </button>

                                <div className='input-form ms-3'>
                                    <Form.Select aria-label="Category"
                                        onChange={(event) => { updateStatus(event.target.value) }}>
                                        <option value="">Select</option>
                                        <option value="1">Active</option>
                                        <option value="0">inActive</option>


                                    </Form.Select>
                                </div>

                            </div>
                            <ul className="pagination justify-content-end">

                                <li >
                                    <NavLink to={PAGE_ROUTE.ADMIN_USERS_CREATE} className="btn btn-outline-success btn-sm me-2">
                                        Add
                                    </NavLink>
                                </li>
                                <li className="page-item disabled">
                                    {isPrev ? <button className="btn btn-outline-primary btn-sm me-2" onClick={prevPage}>Previous</button> : ""}
                                </li>
                                <li className="page-item">
                                    {isNext ? <button className="btn btn-outline-primary btn-sm" onClick={nextPage}>Next</button> : ""}
                                </li>
                            </ul>
                        </nav>
                    </h4>

                    {
                        isLoading && <LoaderComponent height={100} width={100}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-end">
                                    <li className="page-item disabled">
                                        {isPrev ? <button className="btn btn-outline-primary btn-sm me-2" onClick={prevPage}>Previous</button> : ""}
                                    </li>
                                    <li className="page-item">
                                        {isNext ? <button className="btn btn-outline-primary btn-sm" onClick={nextPage}>Next</button> : ""}
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default UserListComponent;