import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import TableComponent from '../../shared/table';
import LoaderComponent from '../../shared/loader';
import { Form } from 'react-bootstrap';
import axios from 'axios';

export default function ProductListComponent() {
    const [currentPage, setcurrentPage] = useState(1);
    const [isNext, setNext] = useState(false);
    const [isPrev, setPrev] = useState(false);
    const[total,setTotal]=useState('');

    const columns = [
        {
            field: 'image',
            lable: 'Image'
        }, {
            field: 'category_name',
            lable: 'Category'
        }, {
            field: 'sub_category_name',
            lable: 'Festival Name'
        }, {
            field: 'downloads',
            lable: 'Download'
        }];
    const [order, setOrder] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataCopy, setDataCopy] = useState([]);
    const [categoryDrp, setCategoryDrpData] = useState([]);
    const [subCategoryDrp, setSubCategoryDrp] = useState([]);
    const [isRecall, setIsRecall] = useState(false);
    const [formData, setFormData] = useState({
        category_id: '',
        sub_category_id: ''
    });
    const nextPage = () => {
        let page = currentPage + 1;
        setcurrentPage(page);
        getPoster(formData.category_id, formData.sub_category_id, page, order, orderBy);
    }

    const prevPage = () => {
        let page = currentPage - 1;
        setcurrentPage(page);
        getPoster(formData.category_id, formData.sub_category_id, page, order, orderBy);
    }

    useEffect(() => {
        getPoster(null, null, 1, "DESC", "created_at");
    }, []);

    useEffect(() => {
        isRecall && getPoster(formData.category_id, formData.sub_category_id, currentPage, order, orderBy);
    }, [isRecall]);

    const getPoster = (category_id, sub_category_id, page, orderSort, orderBy) => {
        setLoading(true);
        axios.post(API_URL.ADMIN_POSTERS_LIST, { page: page, category_id: category_id, sub_category_id: sub_category_id, order_by: orderBy, order_sort: orderSort }).then(res => {
            setData(res.data.data.data);
            setTotal(res.data.data.total);
            if (res.data.data.next_page_url) {
                setNext(true)
            } else {
                setNext(false)
            }
            if (res.data.data.prev_page_url) {
                setPrev(true)
            } else {
                setPrev(false)
            }
            setDataCopy(res.data.data.data);
            setIsRecall(false);
            setLoading(false);
            getCategoryDrpData();
        }, error => {
            setLoading(false);
        })
    };

    const getCategoryDrpData = () => {
        axios.post(API_URL.ADMIN_CATEGORY_LIST, {}).then(res => {
            setCategoryDrpData(res.data.data)
        })
    };

    const getSubCategoryDrpData = (value) => {
        axios.post(API_URL.ADMIN_SUB_CATEGORY_BY_CATEGORY, { category_id: value }).then(res => {
            setSubCategoryDrp(res.data.data)
        })
    };

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_POSTERS_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    };

    const onEdit = (item) => {
        navigate(PAGE_ROUTE.ADMIN_PRODUCT_EDIT + item.id, { replace: true });
    };

    const updateStatus = (val) => {
        setOrder(val);
        getPoster(formData.category_id, formData.sub_category_id, 1, val, orderBy);
    };

    const updateOrderBy = (val) => {
        setOrderBy(val);
        getPoster(formData.category_id, formData.sub_category_id, 1, order, val);
    };


    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>Poster ({total})

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end">
                                <li>
                                    <NavLink to={PAGE_ROUTE.ADMIN_PRODUCT_CREATE} className="btn btn-outline-success btn-sm me-2">
                                        Add
                                    </NavLink>
                                </li>
                                <li className="page-item disabled">
                                    {isPrev ? <button className="btn btn-outline-primary btn-sm me-2" onClick={prevPage}>Previous</button> : ""}
                                </li>
                                <li className="page-item">
                                    {isNext ? <button className="btn btn-outline-primary btn-sm" onClick={nextPage}>Next</button> : ""}
                                </li>
                            </ul>
                        </nav>
                    </h4>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='form-group'>
                                <label>Category</label>
                                <Form.Select aria-label="Category" value={formData.category_id} onChange={(event) => {
                                    getSubCategoryDrpData(event.target.value);
                                    getPoster(event.target.value, null, 1, '');
                                    setFormData({ ...formData, category_id: event.target.value })
                                }}>
                                    value={formData.category_id}
                                    <option value="">Select</option>
                                    {
                                        categoryDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="form-group">
                                <label htmlFor="Sub Category">Sub Category</label>
                                {/* <span className='start'>*</span> */}
                                <Form.Select
                                    aria-label="Sub Category"
                                    value={formData.sub_category_id}
                                    disabled={!formData.category_id}
                                    onChange={(event) => {
                                        setFormData({ ...formData, sub_category_id: event.target.value })
                                        getPoster(formData.category_id, event.target.value, 1, '');
                                    }}>
                                    value={formData.sub_category_id}
                                    <option value="">Select</option>
                                    {
                                        subCategoryDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='form-group'>
                                <label htmlFor="Download">Order By </label>
                                <Form.Select aria-label="Category"
                                    onChange={(event) => { updateOrderBy(event.target.value) }}>
                                    <option value="">Select</option>
                                    <option value="downloads">Download</option>
                                    <option value="created_at">Created At</option>
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='form-group'>
                                <label htmlFor="Download">Download </label>
                                <Form.Select aria-label="Category"
                                    onChange={(event) => { updateStatus(event.target.value) }}>
                                    <option value="">Select</option>
                                    <option value="ASC">Ascending</option>
                                    <option value="DESC">Descending</option>
                                </Form.Select>
                            </div>
                        </div>
                    </div>

                    {
                        isLoading && <LoaderComponent height={80} width={80}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive mt-3'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-end">
                                    <li className="page-item disabled">
                                        {isPrev ? <button className="btn btn-outline-primary btn-sm me-2" onClick={prevPage}>Previous</button> : ""}
                                    </li>
                                    <li className="page-item">
                                        {isNext ? <button className="btn btn-outline-primary btn-sm" onClick={nextPage}>Next</button> : ""}
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    }
                </div>
            </div>
        </div>
    )
}
