import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import { Form, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import Crop from '../../shared/crop';

const BussinessFormComponent = () => {

    const [imagePreview, setImagePreview] = useState('');
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    let { id } = useParams();
    const [error, setError] = useState([]);
    const [userDrp, setUserDrpData] = useState([]);
    const [stateDrp, setStateDrpData] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact_no: '',
        logo: '',
        city: '',
        state_id: '',
        extra_contact_no: '',
        address: '',
        website: '',
        user_id: '',
    });
    useEffect(() => {
        id && onEdit(id);
    }, [id])

    const getUserDrpData = () => {
        axios.post(API_URL.ADMIN_ALL_USERS_LIST, {}).then(res => {
            setUserDrpData(res.data.data)
        })
    }

    useEffect(() => {
        getUserDrpData();
    }, []);

    const getStateDrpData = () => {
        axios.post(API_URL.SITE_ALL_STATE, {}).then(res => {
            setStateDrpData(res.data.data)
        })
    }

    useEffect(() => {
        getStateDrpData();
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        if (isInvalid(formData.name)) {
            toast.error("Name Is Required");
            return false;
        }
        if (isInvalid(formData.email)) {
            toast.error("Email Is Required");
            return false;
        }
        if (isInvalid(formData.contact_no)) {
            toast.error("Contact Number Is Required");
            return false;
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_BUSSINESS_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_BUSSINESS_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Bussiness Updated Successfully', { autoClose: 3000 });
                }
                else {
                    toast.success('Bussiness Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_BUSSINESS_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value == null);
    };

    const onEdit = (id) => {
        axios.post(API_URL.ADMIN_BUSSINESS_GET + id).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    name: response.data.data.name,
                    email: response.data.data.email,
                    contact_no: response.data.data.contact_no,
                    logo: response.data.data.logo,
                    city: response.data.data.city,
                    address: response.data.data.address,
                    extra_contact_no: response.data.data.extra_contact_no,
                    state_id: response.data.data.state_id,
                    website: response.data.data.website,
                    user_id: response.data.data.user_id
                })
            }
        });
    }


    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">{id ? 'Edit' : 'Add'} Bussiness</h4>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <form className="forms-sample" onSubmit={onSubmit} >
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="Category">Users</label>
                                {/* <span className='start' >*</span> */}
                                <Form.Select aria-label="Category" value={formData.user_id} onChange={(event) => {
                                    setFormData({ ...formData, user_id: event.target.value })
                                }}>
                                    value={formData.user_id}
                                    <option value="">Select</option>
                                    {
                                        userDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    name='name'
                                    value={formData.name}
                                    className="form-control"
                                    id="name"
                                    onChange={(event) => {
                                        setFormData({ ...formData, name: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="name">Email</label>
                                <input
                                    type="email"
                                    name='email'
                                    value={formData.email}
                                    className="form-control"
                                    id="email"
                                    onChange={(event) => {
                                        setFormData({ ...formData, email: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="name">Contact Number</label>
                                <input
                                    type="text"
                                    name='contact_no'
                                    value={formData.contact_no}
                                    className="form-control"
                                    id="contact_no"
                                    onChange={(event) => {
                                        setFormData({ ...formData, contact_no: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="name">Contact Number two</label>
                                <input
                                    type="text"
                                    name='extra_contact_no'
                                    value={formData.extra_contact_no}
                                    className="form-control"
                                    id="extra_contact_no"
                                    onChange={(event) => {
                                        setFormData({ ...formData, extra_contact_no: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="Category">State</label>
                                {/* <span className='start' >*</span> */}
                                <Form.Select aria-label="Category" value={formData.state_id} onChange={(event) => {
                                    setFormData({ ...formData, state_id: event.target.value })
                                }}>
                                    value={formData.state_id}
                                    <option value="">Select</option>
                                    {
                                        stateDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="name">City</label>
                                <input
                                    type="text"
                                    name='city'
                                    value={formData.city}
                                    className="form-control"
                                    id="city"
                                    onChange={(event) => {
                                        setFormData({ ...formData, city: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="name">Website</label>
                                <input
                                    type="text"
                                    name='website'
                                    value={formData.website}
                                    className="form-control"
                                    id="website"
                                    onChange={(event) => {
                                        setFormData({ ...formData, website: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="name">Address</label>
                                <input
                                    type="text"
                                    name='address'
                                    value={formData.address}
                                    className="form-control"
                                    id="address"
                                    onChange={(event) => {
                                        setFormData({ ...formData, address: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label>Logo</label>
                                <Crop imagePreview={imagePreview} cropShape='round' guid={''}
                                    onChangeCropper={async ({ fileCropped, fileCroppedBase64 }) => {
                                        setFormData({ ...formData, logo: fileCroppedBase64 })
                                        setImagePreview(fileCroppedBase64);
                                        //   await uploadFile(fileCropped)
                                    }} />
                            </div>
                        </div>
                        {id &&
                            <div className='col-md-4'>
                                <label>Preview</label>
                                <div>
                                    <a href={formData.logo} target="_blank" rel="noopener noreferrer">
                                        <img src={formData.logo} height={100} width={100} alt="logo" />
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='text-end'>
                        {
                            isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                        }
                        <button type="submit" className="btn btn-primary">{id ? 'Update' : 'Create'}</button>
                        <NavLink to={PAGE_ROUTE.ADMIN_BUSSINESS_LIST} className="btn btn-secondary">
                            Cancel
                        </NavLink>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BussinessFormComponent;