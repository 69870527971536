import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
// import './cropper.css';

export const Crop = ({
    cropShape = 'rect',
    aspectRatio = 1/1,
    onChangeCropper,
    name,
    cropBoxHeight = 10,
    cropBoxWidth = 10,
    imagePreview,
    buttonBackGroundColor = "#ccc"
}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const inputRef = useRef(null);
    const [image, setImage] = useState("");
    const [cropper, setCropper] = useState('');
    const fileRef = useRef()

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (files && files.length && files[0].size < 1000000) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
                setIsOpen(true)
            };
            reader.readAsDataURL(files[0]);
        } else (
            toast.error('File Must Be Less Than 1 Mb', { autoClose: 3000 })
        )
    };

    const b64toBlob = async (dataURI) => {
        const base64file = await fetch(dataURI)
        const blobFile = await base64file.blob()
        return blobFile
    }

    const getCropData = async () => {
        if (typeof cropper !== 'undefined') {
            let cropperCanvas = cropper.getCroppedCanvas()
            const fileCropped = await b64toBlob(cropperCanvas.toDataURL('image/jpeg'))
            onChangeCropper({
                fileCropped,
                originalFile: fileRef.current,
                fileCroppedBase64: cropperCanvas.toDataURL('image/jpeg'),
            })
            setIsOpen(false)
        }
    }

    const triggerInvokedFromParent = () => {
        inputRef?.current?.click();
        console.log('TriggerInvokedFromParent');
    };


    return (
        <div>
            <div style={{ display: "block", width: (cropShape !== 'round') ? '100%' : 200, height: (cropShape !== 'round') ? 150 :(cropShape === 'round' && aspectRatio === 9/16 )?350:(cropShape === 'round' && name === 'RIP' )?350: 200, background: buttonBackGroundColor, position: "relative" }} onClick={triggerInvokedFromParent}>
                <input style={{ height: "100%", width: "100%", visibility: "hidden" }} ref={inputRef} type="file" name='fileUploadCx' id="fileUploadCx" onChange={onChange} />
                {(imagePreview) ? <img src={imagePreview} alt="Preview" style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }} /> : null}
            </div>
            <Modal size="lg" backdrop="static" keyboard={false} show={modalIsOpen} onHide={() => setIsOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Crop</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'crop-' + cropShape}>
                    <Cropper
                        aspectRatio={aspectRatio}
                        src={image}
                        zoomable={false}
                        minCropBoxHeight={cropBoxHeight}
                        minCropBoxWidth={cropBoxWidth}
                        background={false}
                        responsive={true}
                        cropBoxResizable={true}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsOpen(false)}>Close</Button>
                    <Button variant="primary" onClick={getCropData}>Crop</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Crop;