import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from '../../shared/loader';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import Crop from '../../shared/crop';



const SliderFormComponent = () => {

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    let { id } = useParams();
    const [error, setError] = useState([]);
    const [imagePreview, setImagePreview] = useState('');
    const [formData, setFormData] = useState(
        {
            title: '',
            sub_title: '',
            image: '',
            image_url: '',
            link: ''
        });

    useEffect(() => {
        id && onEdit();
    }, [id])


    const onSubmit = (e) => {
        e.preventDefault();

        if (isInvalid(formData.title)) {
            toast.error("Title Is Required");
            return false;
        }

        if (!id && isInvalid(formData.image)) {
            toast.error("Image Is Required");
            return false;
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_SLIDER_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_SLIDER_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Slider Updated Successfully', { autoClose: 3000 });
                } else {
                    toast.success('Slider Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_SLIDER_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value == '' || value == null);
    };

    const onEdit = () => {
        axios.post(API_URL.ADMIN_SLIDER_GET + id).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    title: response.data.data.title,
                    sub_title: response.data.data.sub_title,
                    image_url: response.data.data.image_url,
                    link: response.data.data.link
                });
            }
        });
    }

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>{id ? 'Edit' : 'Add'} Slider</h4>
                    {error.length > 0 &&
                        <>
                            {[
                                'danger',
                            ].map((variant) => (
                                <Alert key={variant} variant={variant}>
                                    {error.map((element, key) => {
                                        return (
                                            <div key={key}>{element}</div>
                                        )
                                    })}
                                </Alert>
                            ))}
                        </>
                    }
                    <form className="forms-sample" onSubmit={onSubmit} >
                        <div className='row' >
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Title</label>
                                    {/* <span className='start'>*</span> */}
                                    <input className='form-control'
                                        name='title'
                                        type="text"
                                        value={formData.title}
                                        onChange={(event) => {
                                            setFormData({ ...formData, title: event.target.value })
                                        }}
                                    ></input>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Sub-title</label>
                                    <input
                                        className='form-control'
                                        name='sub_title'
                                        type="text"
                                        value={formData.sub_title}
                                        onChange={(event) => {
                                            setFormData({ ...formData, sub_title: event.target.value })
                                        }}></input>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Link</label>
                                    <input
                                        className='form-control'
                                        name='link'
                                        type="text"
                                        value={formData.link}
                                        onChange={(event) => {
                                            setFormData({ ...formData, link: event.target.value })
                                        }}></input>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label>Image</label>
                                    <Crop imagePreview={imagePreview} aspectRatio={16/9} cropShape='rect' height='auto' width='100%' guid={''}
                                        onChangeCropper={async ({ fileCropped, fileCroppedBase64 }) => {
                                            setFormData({ ...formData, image: fileCroppedBase64 })
                                            setImagePreview(fileCroppedBase64);
                                            //   await uploadFile(fileCropped)
                                        }} />
                                </div>
                            </div>
                            {id &&
                                <div className='col-md-4'>
                                    <label>Preview</label>
                                    <div>
                                        <a href={formData.image_url} target="_blank" rel="noopener noreferrer">
                                            <img src={formData.image_url} height='auto' width='100%' alt="category_image" />
                                        </a>
                                    </div>
                                </div>
                            }
                            <div className='col-md-12 text-right'>
                                <div className='text-end'>
                                    {
                                        isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                                    }
                                    <button type="submit" className="btn btn-primary" onSubmit={onSubmit}>{id ? 'Update' : 'Create'}</button>
                                    <NavLink to={PAGE_ROUTE.ADMIN_SLIDER_LIST} className="btn btn-secondary">
                                        Cancel
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

}
export default SliderFormComponent;