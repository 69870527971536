import { React, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { API_URL, PAGE_ROUTE } from '../../shared/constant';
import TableComponent from '../../shared/table';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
export default function SubCategoryListComponent() {

    let columns = [{
        field: 'name',
        lable: 'Name'
    }, {
        field: 'category_name',
        lable: 'Category'
    }];

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);


    useEffect(() => {
        getCategory();
    }, []);

    useEffect(() => {
        isRecall && getCategory();
    }, [isRecall]);

    const getCategory = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_SUB_CATEGORY_LIST, {}).then(res => {
            setData(res.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    }

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_SUB_CATEGORY_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    }

    const onEdit = (item) => {
        navigate(PAGE_ROUTE.ADMIN_SUB_CATEGORY_EDIT + item.id, { replace: true });
    }
    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>Sub-Category
                        <NavLink to={PAGE_ROUTE.ADMIN_SUB_CATEGORY_CREATE} className="btn btn-primary">
                            Add
                        </NavLink>
                    </h4>

                    {
                        isLoading && <LoaderComponent></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}