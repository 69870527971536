import React, { useEffect, useState, } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import { toast } from 'react-toastify';
import LoaderComponent from '../../shared/loader';
import DatePicker from "react-datepicker";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Crop from '../../shared/crop';
import moment from 'moment'

const SubCategoryFromComponent = () => {

    const [imagePreview, setImagePreview] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const navigate = useNavigate();
    let { id } = useParams();
    const [categoryDrp, setCategoryDrpData] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        category_id: '',
        description: '',
        image: '',
        image_url: '',
        date: '',
    })
    const [error, setError] = useState([]);

    useEffect(() => {
        id && onEdit();
    }, [id])

    const getCategoryDrpData = () => {
        axios.post(API_URL.ADMIN_CATEGORY_LIST, {}).then(res => {
            setCategoryDrpData(res.data.data)
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (isInvalid(formData.name)) {
            toast.error("Name Is Required");
            return false;
        }

        if (isInvalid(formData.category_id)) {
            toast.error("Category Is Required");
            return false;
        }

        // if (isInvalid(formData.description)) {
        //     toast.error("Description Is Required");
        //     return false;
        // }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_SUB_CATEGORY_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_SUB_CATEGORY_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Sub Category Updated Successfully', { autoClose: 3000 });
                } else {
                    toast.success('Sub Category Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_SUB_CATEGORY_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value === null);
    };

    useEffect(() => {
        getCategoryDrpData();
    }, []);

    const onEdit = () => {
        axios.post(API_URL.ADMIN_SUB_CATEGORY_GET + id).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    name: response.data.data.name,
                    category_id: response.data.data.category_id,
                    description: response.data.data.description,
                    image_url: response.data.data.image,
                    date: response.data.data.date
                })
                if (response.data.data.date) {
                    setStartDate(new Date(response.data.data.date))
                }
            }
        });
    }

    const setDate = (dt) => {
        setStartDate(dt)
        setFormData({ ...formData, date: moment(dt).format('YYYY-MM-DD') })
    }

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">{id ? 'Edit' : 'Add'}Sub Category </h4>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <form className="forms-sample" onSubmit={onSubmit}>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                {/* <span className='start'>*</span> */}
                                <input type="text"
                                    name='name'
                                    className="form-control"
                                    id="name"
                                    value={formData.name}
                                    onChange={(event) => {
                                        setFormData({ ...formData, name: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="date">Date</label>
                                <div className='form-control'>
                                    <DatePicker
                                        className="customDatePicker"
                                        selected={startDate}
                                        onChange={(date) => setDate(date)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="Category">Category</label>
                                {/* <span className='start'>*</span> */}
                                <Form.Select aria-label="Category" value={formData.category_id} onChange={(event) => {
                                    setFormData({ ...formData, category_id: event.target.value })
                                }}>
                                    value={formData.category_id}
                                    <option value="">Select</option>
                                    {
                                        categoryDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        {/* <div className='col-md-12'>
                            <div className="form-group">
                                <label htmlFor="Description">Description</label>
                                <Form.Control
                                    as="textarea"
                                    value={formData.description}
                                    style={{ height: '100px' }}
                                    onChange={(event) => {
                                        setFormData({ ...formData, description: event.target.value })
                                    }}
                                />
                            </div>
                        </div> */}
                    </div>
                    <div className='form-group'>
                        <label>Image</label>
                        <Crop imagePreview={imagePreview} cropShape='round' guid={''}
                            onChangeCropper={async ({ fileCropped, fileCroppedBase64 }) => {
                                setFormData({ ...formData, image: fileCroppedBase64 })
                                setImagePreview(fileCroppedBase64);
                                //   await uploadFile(fileCropped)
                            }} />
                    </div>
                    {id &&
                        <div className='col-md-4'>
                            <label>Preview</label>
                            <div>
                                <a href={formData.image_url} target="_blank" rel="noopener noreferrer">
                                    <img src={formData.image_url} height={100} width={100} alt="category_image" />
                                </a>
                            </div>
                        </div>
                    }
                    <div className='text-end'>
                        {
                            isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                        }
                        <button type="submit" className="btn btn-primary">{id ? 'Update' : 'Create'}</button>
                        <NavLink to={PAGE_ROUTE.ADMIN_SUB_CATEGORY_LIST} className="btn btn-secondary">
                            Cancel
                        </NavLink>
                    </div>
                </form>
            </div>
        </div>
    );

}
export default SubCategoryFromComponent;