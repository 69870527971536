import { React, useState, useEffect } from 'react';
import { Form, Alert, Button } from 'react-bootstrap';
import { API_URL, PAGE_ROUTE } from '../../shared/constant';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from '../../shared/loader';

const LoginComponent = () => {

    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState([]);

    const validateForm = () => {
        return email.length > 0 && password.length > 0;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setError([]);
        setLoading(true);
        axios.post(API_URL.LOGIN_URL, { email: email, password: password }).then(res => {
            if (res.data.status === 1) {
                sessionStorage.setItem('access_token', res.data.data.access_token);
                // sessionStorage.setItem('company', res.data.user.name);
                navigate(PAGE_ROUTE.ADMIN_DASHBOARD, { replace: true });
            } else {
                if (res.data && res.data.data) {
                    let errors = [];
                    Object.keys(res.data.data).forEach((key) => {
                        errors.push(res.data.data[key]);
                    });
                    setError(errors);
                }
                toast.error('Invalid Username or Password', { autoClose: 3000 })
            }
            setLoading(false);
        }, error => {
            setError([]);
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        })
    }

    useEffect(() => {
        const access_token = sessionStorage.getItem('access_token');
        if (access_token) {
            navigate(PAGE_ROUTE.ADMIN_DASHBOARD, { replace: true });
        }
    }, [])

    return (
        <>
            <div className='body' style={{ backgroundColor: 'whitesmoke', height: '100vh', paddingTop: '200px' }}>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <div className='container'>
                    <Form className="login-from">
                        <div className="login-text">
                            Sign In
                        </div>
                        <Form.Group size="lg" controlId="email" className='login-page mt-4'>
                            <Form.Control
                                type="email"
                                value={email}
                                className='input-field'
                                readOnly={readOnly}
                                placeholder="Email Address"
                                onFocus={() => setReadOnly(false)}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group size="lg" controlId="password" className='login-page mt-3'>
                            <Form.Control
                                type="password"
                                readOnly={readOnly}
                                className='input-field'
                                onFocus={() => setReadOnly(false)}
                                value={password}
                                placeholder="PassWord"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <div className='text-end w-100 mt-3 loader'>
                            {
                                isLoading &&
                                <div className='me-2'>
                                    <LoaderComponent color={'white'} type={'watch'} height={35} width={35}></LoaderComponent>
                                </div>
                            }
                            <Button onClick={handleSubmit}
                                block="true"
                                size="lg"
                                type="submit"
                                className='login-btn'
                                disabled={!validateForm()}>
                                Login
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
export default LoginComponent;