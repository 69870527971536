import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import { Form, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';

const QuotesForm = () => {

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    let { id } = useParams();
    const [img,setImg]=useState();
    const [categoryDrp, setCategoryDrpData] = useState([]);
    const [languages, setLanguagesData] = useState([]);
    const [error, setError] = useState([])
    const inputRef = useRef(null);
    const [parameters, setParameters] = useState([{
        quote_text: ''
    }])
    const [formData, setFormData] = useState({
        title: '',
        image: '',
        image_url: '',
        category_id: '',
        lang: '',
        tag: '',
        quote_text: ''
    });
    useEffect(() => {
        id && onEdit();
    }, [id])

    const getCategoryDrpData = () => {
        axios.post(API_URL.ADMIN_QUOTES_CATEGORY_LIST, {}).then(res => {
            setCategoryDrpData(res.data.data)
        })
    }

    const findImage = (category_id) => {
        let cRow = categoryDrp.find(item => item.id == category_id)
        setImg(cRow.image)
    }

    const getLanguagesData = () => {
        axios.post(API_URL.ADMIN_LANGUAGES_LIST, {}).then(res => {
            setLanguagesData(res.data.data)
        })
    }

    const generateImage = () => {
        if (!formData.category_id) {
            toast.error("Please select category");
            return
        }
        if (parameters.length < 2) {
            toast.error("Please enter quotes");
            return
        }
        var canvas = document.getElementById("myCanvas");
	    var context = canvas.getContext("2d");
        context.font= "34px 'Courgette', cursive";
        var img1 = new Image();
        img1.crossOrigin="anonymous";
        img1.onload = function () {
            context.drawImage(img1, 0, 0);
            context.lineWidth = 1;
            context.fillStyle = "#ffffff";
            context.lineStyle = "#ffffff";
            var start = 200;
            let q = [];
            parameters.forEach((item) => {
                context.fillText(item.quote_text, 50, start);
                start += 75;
                q.push(item.quote_text)
            })
            setFormData({...formData, image: canvas.toDataURL('image/jpeg'), quote_text: q}) ;
        };
        img1.src = img;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (isInvalid(formData.category_id)) {
            toast.error("Category Is Required");
            return false;
        }
        if (isInvalid(formData.title)) {
            toast.error("Title Is Required");
            return false;
        }
        
        if (isInvalid(formData.image)) {
            toast.error("Please generate image");
            return false;
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_QUOTES_SAVE;
        let body = formData;
        console.log(body)
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_QUOTES_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Category Updated Successfully', { autoClose: 3000 });
                }

                else {
                    toast.success('Category Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_QUOTES_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value == null);
    };

    const onEdit = () => {
        axios.post(API_URL.ADMIN_QUOTES_GET + id).then((response) => {
            if (response.data.status === 1) {
                setParameters(JSON.parse(response.data.data.detail));
                setFormData({
                    title: response.data.data.title,
                    image_url: response.data.data.image,
                    category_id: response.data.data.category_id,
                    lang: response.data.data.lang,
                    tag: response.data.data.tag,
                })
            }
        });
    }

    useEffect(() => {
        getCategoryDrpData();
        getLanguagesData();
    }, []);

    const addParameter = () => {
        let pr = [...parameters]
        pr.push({
            quote_text: '',
        })
        setParameters(pr)
    }

    const removeParameter = (i) => {
        let pr = [...parameters]
        pr.splice(i, 1)
        setParameters(pr)
    }

    const updateValue = (i, name, value) => {
        let pr = [...parameters]
        if (pr[i]) {
            pr[i][name] = value;
        }
        setParameters(pr)
    }

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">{id ? 'Edit' : 'Add'} Quotes</h4>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <form className="forms-sample" onSubmit={onSubmit} >
                    <div className='row'>
                        <div className='col-md-5'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label htmlFor="Category">Category</label>
                                        {/* <span className='start'>*</span> */}
                                        <Form.Select aria-label="Category"
                                            value={formData.category_id}
                                            onChange={(event) => {
                                                findImage(event.target.value);
                                                setFormData({ ...formData, category_id: event.target.value })
                                            }}>
                                            value={formData.category_id}
                                            <option value="">Select</option>
                                            {
                                                categoryDrp.map((res, key) => {
                                                    return (
                                                        <option key={key} value={res.id}>{res.title}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label htmlFor="name">Title</label>
                                        {/* <span className='start'>*</span> */}
                                        <input
                                            type="text"
                                            name='name'
                                            value={formData.title}
                                            className="form-control"
                                            id="name"
                                            onChange={(event) => {
                                                setFormData({ ...formData, title: event.target.value })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label htmlFor="Category">Language</label>
                                        {/* <span className='start'>*</span> */}
                                        <Form.Select aria-label="Category" value={formData.lang} onChange={(event) => {
                                            setFormData({ ...formData, lang: event.target.value })
                                        }}>
                                            value={formData.lang}
                                            <option value="">Select</option>
                                            <option value="english">English</option>
                                            <option value="gujarati">Gujarati</option>
                                            <option value="hindi">Hindi</option>
                                        </Form.Select>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label htmlFor="name">Tags</label>
                                        {/* <span className='start'>*</span> */}
                                        <input
                                            type="text"
                                            name='name'
                                            value={formData.tag}
                                            className="form-control"
                                            id="name"
                                            onChange={(event) => {
                                                setFormData({ ...formData, tag: event.target.value })
                                            }}
                                        />
                                    </div>
                                </div>
                                {parameters.map((item, i) => {
                                    return <div className='row' key={i}>
                                        <div className='col-md-10'>
                                            <div className="form-group">
                                                <label htmlFor="name">Quote Line {i + 1}</label>
                                                <input type="text"
                                                    name='parameter'
                                                    className="form-control"
                                                    value={item.quote_text}
                                                    id="quote_text"
                                                    onChange={(event) => {
                                                        updateValue(i, 'quote_text', event.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <label className='d-block' >&nbsp;</label>
                                                {(i === 0) ?
                                                    <button tabIndex={'-1'} className="btn btn-primary" type='button'
                                                        onClick={addParameter}>Add</button> :
                                                    <button tabIndex={'-1'} className="btn btn-danger" type='button'
                                                        onClick={() => removeParameter(i)}>Delete</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                })}
                                <div className='text-end mt-3 border-top pt-3'>
                                    {
                                        isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                                    }
                                    <button type="button" onClick={generateImage} className="btn btn-primary">Generate</button>
                                    <button type="submit" className="btn btn-primary">{id ? 'Update' : 'Create'}</button>
                                    <NavLink to={PAGE_ROUTE.ADMIN_CATEGORY_LIST} className="btn btn-secondary">
                                        Cancel
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7 text-end'>
                            <img id="scream" src={img} alt="The Scream" style={{width:'100%', display: 'none'}} />
                            <canvas id="myCanvas" width="540" height="960"></canvas>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default QuotesForm